// @flow

export default function formatPrice(price: number | string | void): string {
  if (typeof price === 'number') {
    return price.toFixed(2).replace('.', ',')
  }

  if (typeof price === 'string') {
    return price.replace('.', ',')
  }

  return '0'
}
