import * as React from 'react'
import styled from 'styled-components'

type Props = {
  tags: string[]
  'data-cy-state'?: string
}

export default function SkinnyCurvyLabel(props: Props): JSX.Element {
  const status = React.useMemo(() => {
    for (const tag of props.tags) {
      if (tag === 'not-skinny-rated-model') return 'UNKNOWN'
      if (tag === 'not-skinny-model') return 'CURVY'
      if (tag === 'skinny-model') return 'SKINNY'
    }

    return 'NOT_TAGGED'
  }, [props.tags])

  return (
    <Wrapper data-cy-state={props['data-cy-state']} status={status} className="SkinnyCurvyLabel">
      {status === 'SKINNY' ? 'S' : status === 'CURVY' ? 'C' : '?'}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${(props) => {
    switch (props.status) {
      case 'SKINNY':
        return 'red'
      case 'CURVY':
        return 'green'
      default:
        return 'grey'
    }
  }};
  color: white;
  padding: 5px;
  border-radius: 10px;
`
