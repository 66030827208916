import * as React from 'react'
import styled from 'styled-components'
import { adminMode } from 'config/utils'

type Props = {
  content: string
  'data-cy-state'?: string
  'data-cy-handle'?: string
}

export default function AdminInfo(props: Props): JSX.Element | null {
  if (!adminMode) return null
  const [mount, setMount] = React.useState(false)

  React.useEffect(() => {
    setMount(true)
  }, [])
  return mount ? (
    <Wrapper
      data-cy-state={props['data-cy-state']}
      data-cy-handle={props['data-cy-handle']}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <input value={props.content} />
    </Wrapper>
  ) : null
}

const Wrapper = styled.div`
  line-height: 30px;
  text-align: center;
  overflow-wrap: anywhere;
  > input {
    width: 100%;
    text-align: center;
  }
`
