import useLazyImageSrc from 'hooks/useLazyImageSrc'
import config from 'config'
import React from 'react'
import { useMediaSize } from 'modules/browser'

export default function useProductImage(imgSrc: string, merchantName: string) {
  if (typeof window === 'undefined') return [React.createRef<any>(), defaultImg] as any
  const c = config.modules.cloudinary
  const { activeMs } = useMediaSize('>=', 'LAPTOP_XL')
  let dpr = window.devicePixelRatio
  if (dpr > 1) dpr = 2

  const transform = (imgSrc: string, merchantName: string): string => {
    // backlisted merchant, use merchant image url, assure (force!) httpS/ SSL
    if (c.blacklistedMerchants.indexOf(merchantName) !== -1) {
      return imgSrc
    }

    if (imgSrc.indexOf('productserve') !== -1) {
      const url = /\url=(.*)/.exec(imgSrc)
      if (url) {
        imgSrc = 'https://' + url[1]
        return `${c.imageFetchUrl}${c.imageTransformations.productwidget[activeMs]},dpr_${dpr}/${imgSrc}`
      }
      return imgSrc
    }

    return `${c.imageFetchUrl}${
      c.imageTransformations.productwidget[activeMs]
    },dpr_${dpr}/${encodeURIComponent(imgSrc)}`
  }

  const url = React.useMemo(() => {
    return transform(imgSrc, merchantName)
  }, [activeMs, imgSrc, merchantName])

  return useLazyImageSrc(url, defaultImg, 200)
}

const defaultImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAIAAACjcKk8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAHElEQVQ4y2P4TwFgGNU8qnlU86jmUc2jmoezZgD1FQFieFs2lwAAAABJRU5ErkJggg=='
