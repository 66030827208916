// @flow
import * as React from 'react'
import config from 'config'
import * as t from '../types'

type Output = [boolean, string, string]

export default function useGrouped(product: t.Product): Output {
  return React.useMemo(() => {
    const isDirectBuy =
      config.checkout.enabled && product._tags && product._tags.includes('directbuy')
    let groupedId = ''
    let index = ''

    if (isDirectBuy) {
      const groupedTag =
        (product._tags && product._tags.find((tag) => tag.startsWith('groupedid'))) || ''
      const indexTag =
        (product._tags && product._tags.find((tag) => tag.startsWith('products_inventory'))) || ''
      if (groupedTag) {
        groupedId = groupedTag.split('groupedid-')[1]
        index = indexTag.split('-')[1]
      }
    }

    return [isDirectBuy || false, groupedId, index]
  }, [product])
}
